import React, { useEffect } from "react";
import PracticeAreasBanner from "../../assets/Banner4.jpg";

const PracticeAreas = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="practice-areas-wrapper">
      <div className="practice-areas-banner">
        <img
          src={PracticeAreasBanner}
          alt="banner-about"
          className="w-full h-80"
        />
      </div>
      <div className="practice-areas-content text-justify">
        <div className="sm:px-20 px-8 py-4">
          <div className="title-heading flex justify-center items-center text-2xl text-[#ca8a04] font-bold mb-6">
            <h2>Practice Areas</h2>
          </div>
          <div className="block mb-6">
            <h5 className="text-lg font-bold pb-2 border-b border-b-[#ca8a04] mb-4">
              Criminal litigation
            </h5>
            <p className="mb-4 italic text-sm">
              Criminal litigation is distinct from civil litigation in most
              countries. Civil litigation is a private lawsuit between two
              parties, while criminal litigation is litigation brought by the
              state against an individual.
            </p>
          </div>
          <div className="block mb-6">
            <h5 className="text-lg font-bold pb-2 border-b border-b-[#ca8a04] mb-4">
              Commercial law
            </h5>
            <p className="mb-4 italic text-sm">
              Commercial law, also known as mercantile law or trade law, is the
              body of law that applies to the rights, relations, and conduct of
              persons and business engaged in commerce, merchandising, trade,
              and sales.
            </p>
          </div>
          <div className="block mb-6">
            <h5 className="text-lg font-bold pb-2 border-b border-b-[#ca8a04] mb-4">
              Family and Matrimonial law
            </h5>
            <p className="mb-4 italic text-sm">
              Matrimonial & Family law has four vital branches of law- Hindu
              law, Muslim Law, Christian Law, and Parsi Law. All Four branches
              of law have common legal issues such as essential marriage
              ceremonies, civil marriages before the Court/ Registrar of
              marriage, divorce, child custody, adoption, maintenance, DV Act
              Cases, 498A Cases, inheritance and succession, probate of will,
              etc. It also includes incidental civil law & criminal law.
            </p>
          </div>
          <div className="block mb-6">
            <h5 className="text-lg font-bold pb-2 border-b border-b-[#ca8a04] mb-4">
              Civil litigation
            </h5>
            <p className="mb-4 italic text-sm">
              Civil litigation is a term that applies to any legal dispute where
              two or more parties are seeking monetary damages or a specific
              performance and does not include criminal accusations.
            </p>
          </div>
          <div className="block mb-6">
            <h5 className="text-lg font-bold pb-2 border-b border-b-[#ca8a04] mb-4">
              Employment & Labour law
            </h5>
            <p className="mb-4 italic text-sm">
              Labour laws (also spelled as labor laws), labour code or
              employment laws are those that mediate the relationship between
              workers, employing entities, trade unions, and the government.
              Collective labour law relates to the tripartite relationship
              between employee, employer, and union.
            </p>
          </div>
          <div className="block mb-6">
            <h5 className="text-lg font-bold pb-2 border-b border-b-[#ca8a04] mb-4">
              Intellectual Property Rights
            </h5>
            <p className="mb-4 italic text-sm">
              Intellectual property rights include patents, copyright,
              industrial design rights, trademarks, plant variety rights, trade
              dress, geographical indications, and in some jurisdictions trade
              secrets.
            </p>
          </div>
          <div className="block mb-6">
            <h5 className="text-lg font-bold pb-2 border-b border-b-[#ca8a04] mb-4">
              RERA Matters
            </h5>
            <p className="mb-4 italic text-sm">
              The Real Estate (Regulation and Development) Act, 2016 is an Act
              of the Parliament of India which seeks to protect home-buyers as
              well as help boost investments in the real estate industry. The
              Act establishes a Real Estate Regulatory Authority (RERA) in each
              state for regulation of the real estate sector and also acts as an
              adjudicating body for speedy dispute resolution. The bill was
              passed by the Rajya Sabha on 10 March 2016 and by the Lok Sabha on
              15 March 2016. The Act came into force on 1 May 2016 with 61 of 92
              sections notified. The remaining provisions came into force on 1
              May 2017. The Central and state governments are liable to notify
              the Rules under the Act within a statutory period of six months.
            </p>
          </div>
          <div className="block mb-6">
            <h5 className="text-lg font-bold pb-2 border-b border-b-[#ca8a04] mb-4">
              Consumer Disputes
            </h5>
            <p className="mb-4 italic text-sm">
              A State Consumer Disputes Redressal Commission is an Indian
              autonomous, statutory and constitutional institution formed as a
              quasi judicial body at the state and union territory level under
              Section 24-B of the Consumer Protection Act, 1986 to protect the
              rights of consumers. It is a system of alternate dispute
              resolution between conflicting parties during the process of
              trade. The president of the States Consumer Disputes Redressal
              Commission is appointed by the state government in consultation
              with the Chief Justice of state high court.
            </p>
          </div>
          <div className="block mb-6">
            <h5 className="text-lg font-bold pb-2 border-b border-b-[#ca8a04] mb-4">
              Sexual Harassment at Workplace
            </h5>
            <p className="mb-4 italic text-sm">
              Sexual harassment is a type of harassment involving the use of
              explicit or implicit sexual overtones, including the unwelcome and
              inappropriate promises of rewards in exchange for sexual favors.
              Sexual harassment can be physical and/or verbal. Sexual harassment
              includes a range of actions from verbal transgressions to sexual
              abuse or assault. Harassment can occur in many different social
              settings such as the workplace, the home, school, or religious
              institutions. Harassers or victims can be of any gender.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PracticeAreas;
