/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect } from "react";

const ContactUs = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="contact-us-section">
      <div className="w-full">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.7949826188665!2d77.58333037114355!3d12.984960886611475!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae166b9e8c3487%3A0x43c67d9cdcde447c!2s45%2C%20Palace%20Rd%2C%20Ambedkar%20Veedhi%2C%20Gandhi%20Nagar%2C%20Bengaluru%2C%20Karnataka%20560001!5e0!3m2!1sen!2sin!4v1699107004335!5m2!1sen!2sin"
          width="100%"
          height="350"
          style={{ border: 0 }}
          allowFullScreen={true}
          loading="lazy"
        ></iframe>
      </div>
      <div className="contact-details md:py-10 md:px-20 py-8 px-8">
        <div className="contact-details-heading flex justify-center items-center font-bold mb-12">
          <h1 className="text-2xl pb-4 border-b border-[#cca876]">
            We are based in <span className="">Bangalore, Karnataka</span>
          </h1>
        </div>
        <div className="contact-info-block">
          <div className="grid md:grid-cols-3 grid-cols-1 gap-4">
            <div className="flex items-center flex-col justify-center mb-4 md:mb-0">
              <i className="fa-solid fa-location-dot text-[#cca876] text-4xl mb-4"></i>
              <p className="mb-2 text-md">#4933, 11th floor,</p>
              <p className="text-md">High Point-IV, No.45, Palace Road</p>
              <p className="text-md">Bengaluru</p>
            </div>
            <div className="flex items-center flex-col justify-center mb-4 md:mb-0">
              <i className="fa-solid fa-phone text-[#cca876] text-4xl mb-4"></i>
              <p className="mb-2 text-md">080-43719532</p>
              <p className="text-md">9448142357,9739388891</p>
            </div>
            <div className="flex items-center flex-col justify-center mb-4 md:mb-0">
              <i className="fa-regular fa-clock text-[#cca876] text-4xl mb-4"></i>
              <p className="mb-2 text-md">Timings</p>
              <p className="text-md">10:00AM - 10:00PM</p>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-form bg-[#f5f5f5]">
        <div className="md:py-10 md:px-20 px-8 py-8">
          {/* <div className="flex justify-center items-center text-2xl mb-4">
            <h1>Send a request</h1>
          </div> */}
          <div className="form-elements">
            <div className="grid md:grid-cols-3 grid-cols-1 gap-4 mb-4">
              <div>
                <input
                  type="text"
                  className="p-4 w-full border border-[#EDEDED] outline-none m-0 text-sm border-b-[transparent] shadow-[0_2px_2px_0px_rgba(0,0,0,0.1)] text-[#303030]"
                  placeholder="Name"
                />
              </div>
              <div>
                <input
                  type="text"
                  className="p-4 w-full border border-[#EDEDED] outline-none m-0 text-sm border-b-[transparent] shadow-[0_2px_2px_0px_rgba(0,0,0,0.1)] text-[#303030]"
                  placeholder="Email"
                />
              </div>
              <div>
                <input
                  type="number"
                  className="p-4 w-full border border-[#EDEDED] outline-none m-0 text-sm border-b-[transparent] shadow-[0_2px_2px_0px_rgba(0,0,0,0.1)] text-[#303030]"
                  placeholder="Phone"
                />
              </div>
            </div>
            <div className="mb-4">
              <input
                type="text"
                className="p-4 w-full border border-[#EDEDED] outline-none m-0 text-sm border-b-[transparent] shadow-[0_2px_2px_0px_rgba(0,0,0,0.1)] text-[#303030]"
                placeholder="Subject"
              />
            </div>
            <div className="mb-4">
              <textarea
                className="p-4 w-full border border-[#EDEDED] outline-none m-0 text-sm border-b-[transparent] shadow-[0_2px_2px_0px_rgba(0,0,0,0.1)] text-[#303030]"
                rows={4}
                placeholder="Your Message..."
              />
            </div>
            <div className="flex items-center justify-center">
              <button className="p-4 text-white bg-[#cca876] text-sm">
                Send Message →
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
