import React, { useState } from "react";
//import Image1 from "../../assets/banner.jpg";
import Image2 from "../../assets/banner1.jpg";
import Image3 from "../../assets/Banner4.jpg";
import BackgroundImage from "../../assets/Banner2.jpg";
import ownerImage from "../../assets/mdImage.jpg";
import bannerMain from "../../assets/bannerOwner.jpg";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();
  const [myIndex, setMyIndex] = useState(0);
  const Myobj = [
    {
      pic: Image2,
    },
    {
      pic: bannerMain,
    },
    {
      pic: Image3,
    },
  ];
  const onNextClick = () => {
    if (myIndex === Myobj.length - 1) {
      setMyIndex(0);
    } else {
      setMyIndex(myIndex + 1);
    }
  };
  const onBackClick = () => {
    if (myIndex === 0) {
      setMyIndex(Myobj.length - 1);
    } else {
      setMyIndex(myIndex - 1);
    }
  };
  return (
    <div className="homepage-section">
      <div className="carousel">
        <div className="relative">
          <img
            className="w-screen h-[360px]"
            src={Myobj[myIndex].pic}
            alt="how are you"
          />
          {/* <div
            className="button-arrow-left absolute top-40 left-4 w-10 h-10 rounded-full flex justify-center items-center bg-[#ca8a048f] text-white"
            onClick={() => onBackClick()}
          >
            <i className="fa-solid fa-arrow-left"></i>
          </div>
          <div
            className="button-arrow-right absolute top-40 right-4 w-10 h-10 rounded-full flex justify-center items-center bg-[#ca8a0469] text-white"
            onClick={() => onNextClick()}
          >
            <i className="fa-solid fa-arrow-right"></i>
          </div> */}
        </div>
      </div>
      <div className="about-us-section">
        <div className="sm:py-10 sm:px-20 px-6 py-6">
          <div className="flex justify-center items-center">
            <div className="mb-4">
              <h2 className="mb-1 text-2xl flex justify-center font-semibold text-[#cca876]">
                MALLANGOUD LAW CHAMBERS
              </h2>
              <h2 className="mb-4 text-md italic font-semibold">
                &quot;Bengaluru based law office specializing in litigation and
                Dispute Resolution&quot;
              </h2>
            </div>
          </div>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-4">
            <div className="mr-4">
              <div className="aboutUs-block">
                <h4 className="text-md font-bold mb-2">ABOUT US</h4>
                <p className="mb-4 text-sm text-justify">
                  MALLANGOUD LAW CHAMBERS, is a full-service law office
                  providing service with high professional standards to clients,
                  both individual and corporation. We value our relationship
                  with each client and ensure that our rigorous standards are
                  maintained in all matters, large or small.
                </p>
                <p className="text-sm text-justify mb-4">
                  {" "}
                  The Chamber founded in the year 2004 with the vision to being
                  the highest level of professional service to clients along
                  with traditions of the profession, integrity and sound ethical
                  practices
                </p>
                <p className="text-sm text-justify mb-4">
                  {" "}
                  The key area of practice includes Civil, Criminal, Corporate
                  and Commercial laws with litigation across the Supreme Court
                  of India, High Courts, District Court and Various Tribunals.{" "}
                </p>
                <button
                  className="py-2 px-6 text-white bg-[#cca876] rounded-sm"
                  onClick={() => navigate("/about")}
                >
                  Learn More
                </button>
              </div>
            </div>
            <div className="md:ml-4 ml-0">
              {/* <div className="grid sm:grid-cols-2 grid-cols-1 gap-4 mt-4 mb-6 sm:mb-4">
                <div className="mb-4">
                  <i className="fa-solid fa-scale-balanced text-4xl mb-4 text-[#cca876]"></i>
                  <h4 className="font-semibold text-lg mb-2">
                    Our Services and Valuation
                  </h4>
                  <p className="text-sm">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.{" "}
                  </p>
                </div>
                <div>
                  <i className="fa-solid fa-scale-balanced text-4xl mb-4 text-[#cca876]"></i>
                  <h4 className="font-semibold text-lg mb-2">Legal Analysis</h4>
                  <p className="text-sm">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.{" "}
                  </p>
                </div>
              </div> */}
              {/* <div className="grid sm:grid-cols-2 grid-cols-1 gap-4">
                <div className="mb-4">
                  <i className="fa-solid fa-hammer text-4xl mb-4 text-[#cca876]"></i>
                  <h4 className="font-semibold text-lg mb-2">
                    Case Investigation
                  </h4>
                  <p className="text-sm">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.{" "}
                  </p>
                </div>
                <div>
                  <i className="fa-solid fa-scale-balanced text-4xl mb-4 text-[#cca876]"></i>
                  <h4 className="font-semibold text-lg mb-2">
                    Search Directory
                  </h4>
                  <p className="text-sm">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.{" "}
                  </p>
                </div>
              </div> */}
              <div>
                <img src={ownerImage} alt="owner" className="rounded-sm" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="practice-areas bg-cover"
        style={{ backgroundImage: `url(${BackgroundImage})` }}
      >
        <div className="sm:py-14 sm:px-20 p-6 bg-[#000000a1] text-white">
          <div className="practice-area-section flex justify-center font-bold text-xl mb-6">
            Practice Areas
          </div>
          <div className="grid md:grid-cols-3 grid-cols-1 gap-6 mb-6">
            <div className="border-white border p-6 text-justify rounded-md">
              <div className="flex justify-start items-center mb-4">
                <div className="mr-4">
                  <i className="fa-solid fa-scale-balanced text-4xl text-[#cca876]"></i>
                </div>
                <div>
                  <h4 className="font-bold">Criminal litigation</h4>
                </div>
              </div>
              <p className="text-sm">
                Criminal litigation is distinct from civil litigation in most
                countries. Civil litigation is a private lawsuit between two
                parties, while criminal litigation is litigation brought by the
                state against an individual.
              </p>
            </div>
            <div className="border-white border p-6 text-justify rounded-md">
              <div className="flex justify-start items-center mb-4">
                <div className="mr-4">
                  <i className="fa-solid fa-scale-balanced text-4xl text-[#cca876]"></i>
                </div>
                <div>
                  <h4 className="font-bold">Commercial law</h4>
                </div>
              </div>
              <p className="text-sm">
                Commercial law, also known as mercantile law or trade law, is
                the body of law that applies to the rights, relations, and
                conduct of persons and business engaged in commerce,
                merchandising, trade, and sales.
              </p>
            </div>
            <div className="border-white border p-6 text-justify rounded-md">
              <div className="flex justify-start items-center mb-4">
                <div className="mr-4">
                  <i className="fa-solid fa-scale-balanced text-4xl text-[#cca876]"></i>
                </div>
                <div>
                  <h4 className="font-bold">Family and Matrimonial law</h4>
                </div>
              </div>
              <p className="text-sm">
                Matrimonial & Family law has four vital branches of law- Hindu
                law, Muslim Law, Christian Law, and Parsi Law. All Four branches
                of law have common legal issues such as essential marriage
                ceremonies, civil marriages before the Court/ Registrar of
                marriage, divorce, child custody, adoption, maintenance, DV Act
                Cases, 498A Cases, inheritance and succession, probate of will,
                etc. It also includes incidental civil law & criminal law.
              </p>
            </div>
          </div>
          <div className="grid md:grid-cols-3 grid-cols-1 gap-6">
            <div className="border-white border p-6 text-justify rounded-md">
              <div className="flex justify-start items-center mb-4">
                <div className="mr-4">
                  <i className="fa-solid fa-scale-balanced text-4xl text-[#cca876]"></i>
                </div>
                <div>
                  <h4 className="font-bold">Civil litigation</h4>
                </div>
              </div>
              <p className="text-sm">
                Civil litigation is a term that applies to any legal dispute
                where two or more parties are seeking monetary damages or a
                specific performance and does not include criminal accusations.
              </p>
            </div>
            <div className="border-white border p-6 text-justify rounded-md">
              <div className="flex justify-start items-center mb-4">
                <div className="mr-4">
                  <i className="fa-solid fa-scale-balanced text-4xl text-[#cca876]"></i>
                </div>
                <div>
                  <h4 className="font-bold">Employment & Labour law</h4>
                </div>
              </div>
              <p className="text-sm">
                Labour laws (also spelled as labor laws), labour code or
                employment laws are those that mediate the relationship between
                workers, employing entities, trade unions, and the government.
                Collective labour law relates to the tripartite relationship
                between employee, employer, and union.
              </p>
            </div>
            <div className="border-white border p-6 text-justify rounded-md">
              <div className="flex justify-start items-center mb-4">
                <div className="mr-4">
                  <i className="fa-solid fa-scale-balanced text-4xl text-[#cca876]"></i>
                </div>
                <div>
                  <h4 className="font-bold">Intellectual Property Rights</h4>
                </div>
              </div>
              <p className="text-sm">
                Intellectual property rights include patents, copyright,
                industrial design rights, trademarks, plant variety rights,
                trade dress, geographical indications, and in some jurisdictions
                trade secrets.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="testimonials-section">
        <div className="sm:py-10 sm:px-20 px-10 py-6 font-bold text-lg">
          <div className="grid md:grid-cols-3 grid-cols-1 gap-0">
            <div className="md:border-r md:border-b-0 border-b border-[#abb8c3] p-4">
              <div className="mb-4 flex justify-center items-center">400+</div>
              <div className="text-[#cca876] text-sm font-bold flex justify-center items-center">
                TRUSTED CLIENTS
              </div>
            </div>
            <div className="md:border-r md:border-b-0 border-b border-[#abb8c3] p-4">
              <div className="mb-4 flex justify-center items-center">98%</div>
              <div className="text-[#cca876] text-sm font-bold flex justify-center items-center">
                SUCCESSFUL CASES
              </div>
            </div>
            <div className="p-4">
              <div className="mb-4 flex justify-center items-center">1909</div>
              <div className="text-[#cca876] text-sm font-bold flex justify-center items-center">
                CASES HANDLED
              </div>
            </div>
            {/* <div className="p-4">
              <div className="mb-4 flex justify-center items-center">1700+</div>
              <div className="text-[#cca876] text-sm font-bold flex justify-center items-center">
                TRUSTED CLIENTS
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
