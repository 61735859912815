import React, { useEffect } from "react";
import AboutUsBanner from "../../assets/bannerBooks.jpg";

const AboutUs = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="about-us-section">
      <div className="about-us-banner">
        <img src={AboutUsBanner} alt="banner-about" className="w-full h-80" />
      </div>
      <div className="about-content text-justify">
        <div className="sm:px-20 px-8 py-4">
          <div className="title-heading flex justify-center items-center text-2xl text-[#ca8a04] font-bold mb-6">
            <h2>About Us</h2>
          </div>
          <h4 className="text-xl font-bold mb-2">H MALLANGOUD</h4>
          <h5 className="font-bold mb-4 italic">FOUNDER & MANAGING PARTNER</h5>
          <p className="mb-4">
            MALLANGOUD LAW CHAMBERS, is a full-service law office providing
            service with high professional standards to clients, both individual
            and corporation. We value our relationship with each client and
            ensure that our rigorous standards are maintained in all matters,
            large or small.{" "}
          </p>
          <p className="mb-4">
            {" "}
            He has had the opportunity of dealing with the matters involving
            complex legal issues and has acquired expertise in numerous civil,
            criminal and matrimonial laws. He has over 30 years of experience in
            service, criminal and civil matters in Supreme Court of India, the
            Karnataka High Court, the subordinate Courts and service tribunals.
          </p>
          <p className="mb-4">
            Experienced in a wide array of matters, Mr. MallanGoud specializes
            in Divorce & Family Law, Commercial Law, and matters pertaining to
            Negotiable Instruments.{" "}
          </p>
        </div>
        <div className="sm:px-20 px-8 py-4">
          <h4 className="text-xl font-bold mb-2">MEMBERS</h4>
          <p className="mb-4">
            Chamber is not a office, nor are its members partners or employees.
            Rather, Chamber is comprised of individual advocates each of whom is
            a self-employed sole practitioner. Members of Chambers are commonly
            retained by opposing sides in the same dispute, both in litigation
            and dispute resolution, with protocols in place to safeguard
            confidentiality.{" "}
          </p>
        </div>
        <div className="sm:px-20 px-8 py-4">
          <h4 className="text-xl font-bold mb-2">DISCLAIMER</h4>
          <p className="mb-4">
            The Bar Council of India, whose rules govern our practices, does not
            permit solicitation of work advertisements by practicing advocates
            in any form or manner.{" "}
          </p>
          <p className="mb-4">
            This website has been designed only for the purposes of
            dissemination of basic information about MallanGoud Law Chambers,
            and the information which is otherwise available on the internet,
            various public platforms and social media. However, MallanGoud Law
            Chambers shall not be responsible for any reliance that a reader
            places on such information and shall not be liable for any loss or
            damage caused due to any inaccuracy in or exclusion of any
            information. Visitors are advised to confirm the veracity of the
            same from independent and expert sources.
          </p>
          <p className="mb-4">
            This website is in no way an attempt to advertise or solicit
            clients, and does not seek to create or invite and advocate – client
            relationship. The information provided on this website is to
            facilitate access to basic information about MallanGoud Law
            Chambers.
          </p>
          <p className="mb-4">
            By clicking on “I AGREE”, the visitor acknowledges that the
            information provided in this website does not amount to advertising
            or solicitation, and is meant only for his/her understanding about
            our activities and who we are.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
