import { Drawer } from "@mui/material";
import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";

const Header = () => {
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  type Anchor = "top" | "left" | "bottom" | "right";

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const list = (anchor: Anchor) => (
    <div
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div className="bg-white w-40 p-4">
        <div className="header-menu-list text-sm font-bold">
          <div className="p-2">
            <NavLink to={"/"}>HOME</NavLink>
          </div>
          <div className="p-2">
            <NavLink to={"/about"}>ABOUT US</NavLink>
          </div>
          <div className="p-2">
            <NavLink to={"/practiceAreas"}>PRACTICE AREAS</NavLink>
          </div>
          <div className="p-2">
            <NavLink to={"/contact"}>CONTACTS</NavLink>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="header-container">
      <div className="header-top bg-black py-4 text-white flex-col items-center justify-center flex sm:justify-between sm:items-center sm:flex-row sm:px-20 px-3 text-xs sm:text-sm">
        <div className="header-top-left mb-2 sm:mb-0 flex">
          <div className="mr-3 mb-2 sm:mb-0 sm:mr-6 flex">
            <div className="mr-2">
              <i className="fa-solid fa-envelope"></i>
            </div>
            <a href="mailto:mallangoudlawchambers@gmail.com">
              mallangoudlawchambers@gmail.com
            </a>
          </div>
          <div className="flex sm:mr-4 mr-0">
            <div className="mr-2">
              <i className="fa-solid fa-mobile"></i>
            </div>
            <div>080-43719532</div>
          </div>
        </div>
        <div className="header-top-right flex">
          <div className="mr-4">
            <a href="http://www.demo.com">
              <i className="fa-solid fa-wifi"></i>
            </a>
          </div>
          <div className="mr-4">
            <a href="http://www.demo.com">
              <i className="fa-brands fa-facebook"></i>
            </a>
          </div>
          <div className="mr-4">
            <a href="http://www.demo.com">
              <i className="fa-brands fa-google-plus-g"></i>
            </a>
          </div>
          <div className="mr-4">
            <a href="http://www.demo.com">
              <i className="fa-brands fa-linkedin-in"></i>
            </a>
          </div>
          <div className="mr-4">
            <a href="http://www.demo.com">
              <i className="fa-brands fa-pinterest"></i>
            </a>
          </div>
          <div className="">
            <a href="http://www.demo.com">
              <i className="fa-brands fa-twitter"></i>
            </a>
          </div>
        </div>
      </div>
      <div className="header-menu-section sm:px-20 px-8 py-4 flex justify-between">
        <div className="header-logo text-2xl text-[#ca8a04] font-bold">
          LAWYER
        </div>
        <div className="header-menu-list sm:flex items-center text-sm font-bold hidden">
          <div className="mr-2">
            <NavLink
              to={"/"}
              className={({ isActive }) =>
                isActive ? "bg-[#ca8a04] text-white p-2 rounded-md" : "p-2"
              }
            >
              HOME
            </NavLink>
          </div>
          <div className="mr-2">
            <NavLink
              to={"/about"}
              className={({ isActive }) =>
                isActive ? "bg-[#ca8a04] text-white p-2 rounded-md" : "p-2"
              }
            >
              ABOUT US
            </NavLink>
          </div>
          <div className="mr-2">
            <NavLink
              to={"/practiceAreas"}
              className={({ isActive }) =>
                isActive ? "bg-[#ca8a04] text-white p-2 rounded-md" : "p-2"
              }
            >
              PRACTICE AREAS
            </NavLink>
          </div>
          <div>
            <NavLink
              to={"/contact"}
              className={({ isActive }) =>
                isActive ? "bg-[#ca8a04] text-white p-2 rounded-md" : "p-2"
              }
            >
              CONTACTS
            </NavLink>
          </div>
        </div>
        <div className="block sm:hidden">
          <div onClick={toggleDrawer("left", true)} className="cursor-pointer">
            <i className="fa-solid fa-bars"></i>
          </div>
          <Drawer
            anchor={"left"}
            open={state["left"]}
            onClose={toggleDrawer("left", false)}
            className="sm:hidden"
          >
            {list("left")}
          </Drawer>
        </div>
      </div>
    </div>
  );
};

export default Header;
