import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout/Layout";
import Home from "./components/Screens/Home";
import ContactUs from "./components/Screens/ContactUs";
import AboutUs from "./components/Screens/AboutUs";
import PracticeAreas from "./components/Screens/PracticeAreas";

function App() {
  return (
    <BrowserRouter>
      <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<Home />}></Route>
        <Route path="/about" element={<AboutUs />}></Route>
        <Route path="/practiceAreas" element={<PracticeAreas />}></Route>
        <Route path="/contact" element={<ContactUs />}></Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
