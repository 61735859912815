import React from "react";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  return (
    <div className="footer text-sm">
      <div className="bg-black md:py-14 sm:py-10 py-8 md:px-20 sm:px-14 px-10 text-white">
        <div className="grid sm:grid-cols-3 grid-cols-1 gap-8 md:gap-10 lg:gap-16">
          <div className="">
            <h4 className="text-white font-bold text-lg mb-4">About</h4>
            <p className="mb-6 text-justify font-thin">
              MALLANGOUD LAW CHAMBERS, is a full-service law office providing
              service with high professional standards to clients, both
              individual and corporation.
            </p>
            <button
              className="px-8 py-2 border border-[#cca876] text-[#cca876]"
              onClick={() => navigate("/about")}
            >
              Read More...
            </button>
          </div>
          <div className="">
            <h4 className="text-white font-bold text-lg mb-4">
              Practice Areas
            </h4>
            <div>
              <ul className="text-[#cca876]">
                <li
                  className="mb-2 cursor-pointer"
                  onClick={() => navigate("/practiceAreas")}
                >
                  Criminal litigation →
                </li>
                <li
                  className="mb-2 cursor-pointer"
                  onClick={() => navigate("/practiceAreas")}
                >
                  Commercial law →
                </li>
                <li
                  className="mb-2 cursor-pointer"
                  onClick={() => navigate("/practiceAreas")}
                >
                  Family and Matrimonial law →
                </li>
                <li
                  className="mb-2 cursor-pointer"
                  onClick={() => navigate("/practiceAreas")}
                >
                  Civil litigation →
                </li>
                <li
                  className="mb-2 cursor-pointer"
                  onClick={() => navigate("/practiceAreas")}
                >
                  Employment & Labour law →
                </li>
                <li
                  className="mb-2 cursor-pointer"
                  onClick={() => navigate("/practiceAreas")}
                >
                  Intellectual Property Rights →
                </li>
              </ul>
            </div>
          </div>
          <div className="">
            <h4 className="text-white font-bold text-lg mb-4">Contact</h4>
            <p className="mb-3">
              #4933, 11th floor, High Point-IV, No.45, Palace Road,
              Bengaluru-560001
            </p>
            <p className="mb-2">Tel: 080-43719532</p>
            <p className="mb-2">Mobile: 9448142357</p>
            <p className="mb-2">Mobile: 9739388891</p>
            <p className="mb-3">
              E-mail:{" "}
              <a
                href="mailto:mallangoudlawchambers@gmail.com"
                className="text-[#cca876]"
              >
                mallangoudlawchambers@gmail.com
              </a>
            </p>
            <p
              className="text-[#cca876] cursor-pointer"
              onClick={() => navigate("/contact")}
            >
              Get directions on the map →
            </p>
          </div>
        </div>
      </div>
      <div className="copyrights bg-black text-white border-t border-[#cca876] p-4 flex justify-center items-center">
        copyright © 2023 Lawyer, India. All rights reserved
      </div>
    </div>
  );
};

export default Footer;
